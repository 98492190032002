.portfolio-page {
    background-color: #181818;
    color: #fff;
    font-family: 'Arial', sans-serif;
    width: 100%;
    margin: 0 auto;
    margin-left: 20px;
  }
  
  .portfolio-section {
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
  }
  
  .portfolio-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .portfolio-tabs button {
    background: none;
    border: none;
    color: #b3b3b3;
    font-size: 1em;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .portfolio-tabs button.active {
    color: #1bb0dd;
  }
  
  .portfolio-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .portfolio-item {
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    width: calc(50% - 10px); 
  }
  
  .portfolio-item img {
    width: 100%;
    height: 300px;
    object-fit: cover; 
  }
  
  .portfolio-item-info {
    padding: 10px;
  }
  
  .portfolio-item-info .category-label {
    background-color: #1bb0dd;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 0.8em;
    color: #fff;
    display: inline-block;
    margin-bottom: 10px;
  }
  
.portfolio-item-info .category-label.project,
.portfolio-item-info .category-label.projet {
  background-color: #ff5722; 
}


.portfolio-item-info .category-label.certificate,
.portfolio-item-info .category-label.certificat {
  background-color:  #03a9f4;
}
  
  .portfolio-item-info .category-label.badge {
    background-color: #ffc107;
  }
  
  .portfolio-item-info h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .portfolio-item-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .portfolio-item-info a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 1300px) {
    .portfolio-page {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
  @media (max-width: 768px) {
    .portfolio-items {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .portfolio-item {
      width: 100%; 
    }
  }
  