.contact-page {
    padding: 0px;
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    margin-left: 20px;
  }

.contact-form {
    display: flex;
    flex-direction: column;
}
  
.form-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
  
.form-group input, .form-group textarea {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    flex: 1;
}
  
.form-group textarea {
    resize: none;
    height: 100px;
}
  
.submit-btn {
    align-self: flex-end;
    background: linear-gradient(90deg, #007bff 0%, #00d2ff 100%);
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
  
.submit-btn:hover {
    opacity: 0.8;
}
  
.contact-footer {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9em;
}
  
.contact-footer a {
    color: #00d2ff;
    text-decoration: none;
}
  
.contact-footer a:hover {
    text-decoration: underline;
}
@media (max-width: 1300px) {
    .contact-page {
        margin-left: 0px;
        margin-top: 20px;
        
      
      }
}
@media (max-width: 768px) {
    .form-group {
        display: flex;
        flex-direction: column;
    }
    .form-group textarea {
        resize: none;
        min-height: 100px;
    }
  }