.resume-page {
    margin-left: 20px;
    
    color: #fff;
    font-family: 'Arial', sans-serif;
    width: 100%;
}

.resume-section {
    margin-bottom: 30px;

}
.resume-icon {
    
    color: #1bb0dd; 
  }
.resume-section h1, .resume-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
}

.resume-section h2 {
    
    padding-bottom: 20px;
}

.resume-section h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 8%;
    background: linear-gradient(90deg, #ff5722 0%, #00d2ff 100%);
    border-radius: 2px;
}
.resume-section h2 svg {
    margin-right: 10px;
}

.timeline {
    position: relative;
    border-left: 2px solid #1bb0dd;
    margin-left: 10px;   
}

.timeline-item {
    position: relative;
    padding-left: 40px;
}

.timeline-icon {
    margin-top: 10px;
    position: absolute;
    left: -8px;
    top: 0;
    width: 15px;
    height: 15px;
    background-color: #1bb0dd;
    border-radius: 50%;
}

.timeline-content {
    padding-top: 0px;
    border-radius: 5px;
}



.timeline-content span {
    font-size: 0.9em;
    color: #b3b3b3;
}
.timeline-item h3 {
    font-size: 1.8em; 
    margin-bottom: 8px;
    margin-top: 0px;
}

.timeline-item span {
    color: aliceblue;
    display: block;
    margin-bottom: 10px; 
}

.timeline-item p {
    margin-bottom: 10px; 
}

.timeline-content p {
    margin: 0;
}

.skills {
    margin-top: 20px;
    

    border-radius: 10px;
}

.skills h2 {
    margin-bottom: 20px;
    display: flex;

}



.skill-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.skill-item span {
    flex: 1;
}

.progress-bar {
    flex: 2;
    margin-bottom: 15px;
    background-color: #333;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.progress {
    background: linear-gradient(90deg, #1bb0dd 0%, #00d2ff 100%);
    height: 30px;
    border-radius: 5px;
    display: flex;
    color: #fff;
    font-weight:500;
    width: 100%;
    justify-content: space-between; 
}

.progress div{
    padding-top: 5px;
}
.progress .item {
    padding-left: 10px;
    line-height: 20px;
}

.progress .pr {
    padding-right: 10px;
    line-height: 20px;
}

a {
    color: #1bb0dd;;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: 1300px) {
    .resume-page {
        margin-left: 0px;
        margin-top: 20px;
    }
}
